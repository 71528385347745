<template>
    <div class="GroupPeople">
        <el-card shadow="never" style="margin-top: 8px">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form inline size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="机构组">
                            <el-input v-model="form.name" readonly />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="查询条件">
                            <el-input placeholder="姓名/手机号" v-model="staff.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="员工类型">
                            <el-select v-model="staff.staffType">
                                <el-option value="" label="请选择" />
                                <el-option :value="1" label="业务人员" />
                                <el-option :value="0" label="管理人员" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleQuery" size="small">查询</el-button>
            <el-button type="primary" @click="handleQueryAndAdd" size="small">查询并添加</el-button>
            <el-button type="primary" @click="handleAdd" size="small">添加人员</el-button>
            <el-button type="primary" @click="handleExport" size="small">导出</el-button>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                ref="people"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="460"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />

                <el-table-column label="姓名" width="160" prop="realName" />
                <el-table-column label="手机号" width="100" prop="mobile" />
                <el-table-column label="性别" width="60" prop="sex">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex | sex }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="年龄" width="60" prop="age" />
                <!--                <el-table-column label="身份证号" width="140" prop="idNumber"></el-table-column>-->
                <el-table-column label="员工类型" width="120" prop="type">
                    <template slot-scope="scope">
                        <span>{{ scope.row.type | type }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="归属部门" width="240" prop="deptName" />
                <el-table-column label="绑定角色" width="120" prop="roles">
                    <template slot-scope="scope">
                        <span>{{ scope.row.roles | roles }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="收银机最大折扣" width="130" prop="discount" />

                <el-table-column label="操作" min-width="210" header-align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" @click="handleShiftOut(scope.row)">移出机构组 </el-button>
                        <el-button size="mini" @click="handleAssignDept(scope.row)">指定机构</el-button>
                        <el-button
                            size="mini"
                            @click="changeReferrer(scope.row)"
                            v-if="hasPrivilege('biz.member.update.batch.developMember')"
                            >变更推荐人
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="quit(scope.row)"
                            v-if="hasPrivilege('biz.system.staff.leave.dept')"
                            >离职</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <pick-up-staff ref="pickUpStaff" @ok="handlePickUpStaffOk" />
        <el-dialog
            title="指定员工归属或管理的机构"
            :visible.sync="assignDept.dialogFormVisible"
            width="55%"
            top="60px"
            class="staffBindDept"
            :close-on-click-modal="false"
        >
            <p style="color: red">
                如员工在朗勤心选APP中存在未使用的商分，请在收银系统后台--电商管理--商分兑换页面，兑换完所有可用商分后再执行本操作
            </p>
            <el-card shadow="never">
                <el-row>
                    <el-form :inline="true" size="small">
                        <el-radio-group v-model="assignDept.selected[0]" v-if="assignDept.assignType == 1">
                            <template v-for="item in assignDept.deptsInGroup">
                                <el-form-item :key="item.code">
                                    <el-radio :label="item.code">{{ item.name }}</el-radio>
                                </el-form-item>
                            </template>
                        </el-radio-group>
                        <el-checkbox-group v-model="assignDept.selected" v-if="assignDept.assignType == 0">
                            <template v-for="item in assignDept.deptsInGroup">
                                <el-form-item :key="item.code">
                                    <el-checkbox :label="item.code">{{ item.name }}</el-checkbox>
                                </el-form-item>
                            </template>
                        </el-checkbox-group>
                    </el-form>
                </el-row>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="assignDept.dialogFormVisible = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleAssignDeptOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="变更推荐人"
            :visible.sync="assignDept.dialogChangeReferrer"
            width="55%"
            top="60px"
            :close-on-click-modal="false"
        >
            <el-card shadow="never">
                <el-row>
                    <span class="pClass">如新变更员工未与（朗勤心选APP）合作,则此会员将归八号地</span>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <span class="spanClass">将</span><span class="pClassBlack">{{ staffName }}</span
                        ><span class="spanClass">发展的会员变更给员工 ： </span>
                        <el-input v-model="assignStaffName" style="width: 180px" size="small" type="text" readonly
                            ><i class="el-icon-circle-close qing-icon" slot="suffix" @click="handleIconClick" />
                        </el-input>
                        <el-button type="primary" @click="choiceStaffButton" size="small">选择员工</el-button>
                    </el-col>
                    <pick-up-staff ref="pickUpStaffTable" @ok="choiceStaff" />
                </el-row>
            </el-card>
            <div slot="footer" class="dialog-footer">
                <el-button @click="assignDept.dialogChangeReferrer = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleChangeReferrer" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="移出机构组"
            :visible.sync="shiftOutVisible"
            width="40%"
            top="60px"
            :close-on-click-modal="false"
            :before-close="handleClose"
        >
            <el-alert
                title="将员工移出机构组，请先分配该员工商友去向，并将可用商分全部兑换完成"
                type="warning"
                show-icon
                :closable="false"
            >
                (仅移交朗勤心选APP发展的商友)
            </el-alert>
            <div class="option">
                <el-radio-group v-model="cleanFriendsFlag">
                    <div class="label-tit"><el-radio :label="1">归还机构</el-radio></div>
                    <div class="label-detil">商友归还机构后，将随机分配给机构其他成员</div>
                    <div class="label-tit"><el-radio :label="0">跟随员工</el-radio></div>
                    <div class="label-detil">商友将与该员工保持商友关系</div>
                    <div class="label-tit"><el-radio :label="2">指定员工移交</el-radio></div>
                    <div class="label-detil">商友将移交给指定的员工</div>
                </el-radio-group>
            </div>
            <div>
                <el-input
                    size="small"
                    v-model="assignStaffName"
                    :clearable="true"
                    readOnly
                    @clear="handleIconClick"
                ></el-input
                ><el-button type="primary" size="small" @click="designatedStaff" :disabled="cleanFriendsFlag !== 2"
                    >选择员工</el-button
                >
            </div>
            <pick-up-staff ref="pickUpStaffTable" @ok="choiceStaff" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose" size="small">取 消</el-button>
                <el-button type="primary" @click="handleShiftOutOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog
            title="员工离职"
            :visible.sync="quitVisible"
            width="40%"
            top="60px"
            :close-on-click-modal="false"
            :before-close="handleClose"
        >
            <el-alert
                title="员工离职，请先分配该员工商友去向，并将可用商分全部兑换完成"
                type="warning"
                show-icon
                :closable="false"
            >
                (仅移交朗勤心选APP发展的商友)
            </el-alert>
            <div class="option">
                <el-radio-group v-model="cleanFriendsFlag">
                    <div class="label-tit"><el-radio :label="1">归还机构</el-radio></div>
                    <div class="label-detil">商友归还机构后，将随机分配给机构其他成员</div>
                    <div class="label-tit"><el-radio :label="2">指定员工承接</el-radio></div>
                    <div class="label-detil">商友将移交给指定的员工</div>
                </el-radio-group>
            </div>
            <div>
                <el-input
                    size="small"
                    v-model="assignStaffName"
                    :clearable="true"
                    readOnly
                    @clear="handleIconClick"
                ></el-input
                ><el-button type="primary" size="small" @click="designatedStaff" :disabled="cleanFriendsFlag !== 2"
                    >选择员工</el-button
                >
            </div>
            <pick-up-staff ref="pickUpStaffTable" @ok="choiceStaff" />
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleClose" size="small">取 消</el-button>
                <el-button type="primary" @click="quitOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from '../../../../js/Util';
import UrlUtils from '../../../../js/UrlUtils';
import PickUpStaff from '../../../../views/components/PickUpStaff';

export default {
    name: 'GroupPeople',
    props: ['form'],
    components: { PickUpStaff },
    data() {
        return {
            staff: {
                search: '',
                deptGroupCode: this.form.code,
                staffType: '',
            },
            url: {
                query: '/system/staff/queryExtend',
                queryAndAddSave: '/system/deptGroup/addStaffToDeptGroup',
                shiftOut: '/system/deptGroup/removeStaffFromDeptGroup',
                deptsInGroup: '/system/dept/deptGroupStaffRelatedDepts',
                allotedDepts: '/system/dept/deptGroupStaffRelatedDepts',
                allotDept: '/system/staff/allotDept',
                changeReferrer: '/system/staff/changeReferrer',
                quit: '/system/staff/disable/handle',
            },
            tableData: [],
            assignDept: {
                deptsInGroup: [],
                dialogFormVisible: false,
                dialogChangeReferrer: false,
                staff: '',
                selected: [],
                selectedBackup: [],
                assignType: '',
            },
            assignStaffCode: '',
            assignStaffName: '',
            staffName: '',
            row: null,
            shiftOutVisible: false,
            quitVisible: false,
            cleanFriendsFlag: '',
            staffCode: '',
            realName: '',
        };
    },
    mounted() {
        this.handleQuery();
        UrlUtils.QueryRemote(
            this,
            this.url.deptsInGroup + '?deptGroupCode=' + this.form.code + '&staffCode=' + this.$store.state.session.code,
            (data) => {
                this.assignDept.deptsInGroup = data;
            }
        );
    },
    methods: {
        //离职
        quit(row) {
            this.quitVisible = true;
            this.staffCode = row.code;
            this.realName = row.realName;
            this.cleanFriendsFlag = '';
            this.assignStaffCode = '';
            this.assignStaffName = '';
        },
        quitOk() {
            if (this.cleanFriendsFlag === '') {
                this.$message.error('请选择离职方式');
                return;
            }
            if (this.cleanFriendsFlag === 2 && this.assignStaffCode === '') {
                this.$message.error('请选择指定员工');
                return;
            }
            UrlUtils.PostRemote(
                this,
                this.url.quit,
                {
                    code: this.staffCode,
                    changeFriendsOwnerType: this.cleanFriendsFlag,
                    friendsNewParentStaffCode: this.assignStaffCode,
                    message: '是否确认将' + this.realName + '离职?',
                },
                null,
                () => {
                    this.$message.success('操作成功');
                    this.quitVisible = false;
                    this.handleQuery();
                }
            );
        },
        handleClose() {
            this.handleIconClick();
            this.cleanFriendsFlag = '';
            this.quitVisible = false;
            this.shiftOutVisible = false;
        },
        changeReferrer(row) {
            this.assignDept.dialogChangeReferrer = true;
            this.assignStaffCode = row.code;
            this.assignStaffName = row.realName;
            this.staffName = row.realName;
            this.assignDept.staff = row.code;
        },
        handleIconClick() {
            this.assignStaffCode = '';
            this.assignStaffName = '';
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.staff };
            Util.queryTable(_this, _this.url.query, _params, (rst) => {
                _this.tableData = rst.data;
            });
        },
        handleQueryAndAdd() {
            const _this = this;
            if (!_this.staff.search) {
                return;
            }
            const _params = { params: { deptType: _this.form.deptType, search: _this.staff.search } };
            Util.queryTable(_this, _this.url.query, _params, (rst) => {
                const existCodes = this.tableData.map((r) => r.code);

                const _p = {
                    code: _this.form.code,
                    staffs: rst.data.map((a) => a.code).filter((r) => !existCodes.includes(r)),
                    message: '查询结果将自动添加到本机构组中,是否确认?',
                };
                if (!rst.data.length || !_p.staffs.length) {
                    this.$message.warning('未找到或已存在于其他机构组，请重新输入查询条件');
                    return;
                }
                UrlUtils.PostRemote(_this, _this.url.queryAndAddSave, _p, null, () => {
                    _this.tableData = _this.duplicate(rst.data.concat(_this.tableData), 'code');
                    _this.staff.search = '';
                });
            });
        },
        choiceStaffButton() {
            const extendParams = {
                deptType: this.form.deptType,
                selectionChangeType: 'one',
                search: '',
                staffType: '',
            };
            this.$refs.pickUpStaffTable.show(extendParams);
        },
        designatedStaff() {
            const extendParams = {
                deptType: this.form.deptType,
                selectionChangeType: 'one',
                search: '',
                staffType: '',
                needCheckFriendsParentStaffFlag: true,
            };
            this.$refs.pickUpStaffTable.show(extendParams);
        },
        handleAdd() {
            const extendParams = {
                deptType: this.form.deptType,
                ignoreStaffCodes: this.tableData.map((a) => a.code).join(','),
            };
            this.$refs.pickUpStaff.show(extendParams);
        },
        handleExport() {
            UrlUtils.Export(this, '机构组人员', '/system/deptGroup/exportStaffFromDeptGroup', this.staff);
        },
        handleShiftOut(row) {
            this.shiftOutVisible = true;
            this.staffCode = row.code;
            this.cleanFriendsFlag = '';
            this.assignStaffCode = '';
            this.assignStaffName = '';
        },
        handleShiftOutOk() {
            if (this.cleanFriendsFlag === '') {
                this.$message.error('请选择移出方式');
                return;
            }
            if (this.cleanFriendsFlag === 2 && this.assignStaffCode === '') {
                this.$message.error('请选择指定员工');
                return;
            }
            UrlUtils.PatchRemote(
                this,
                this.url.shiftOut,
                {
                    staffCode: this.staffCode,
                    deptGroupCode: this.form.code,
                    changeFriendsOwnerType: this.cleanFriendsFlag,
                    friendsNewParentStaffCode: this.assignStaffCode,
                    message: '是否确认将此员工移出机构组?',
                },
                null,
                () => {
                    this.$message.success('移除成功');
                    this.shiftOutVisible = false;
                    this.handleQuery();
                }
            );
        },
        //移出机构组
        removeOrganizationGroup(cleanFriendsFlag, staffCode) {
            const _this = this;
            UrlUtils.PatchRemote(
                this,
                this.url.shiftOut,
                {
                    staffCode,
                    deptGroupCode: this.form.code,
                    changeFriendsOwnerType: cleanFriendsFlag,
                    message: '是否确认将此员工移出机构组?',
                },
                null,
                () => {
                    _this.$message.success('移除成功');
                    this.handleQuery();
                }
            );
        },
        choiceStaff(val) {
            if (val.length > 1) {
                this.$message.info('勾选的员工不能多于一个!');
            } else {
                this.assignStaffCode = val[0].code;
                this.assignStaffName = val[0].realName;
            }
        },
        handleChangeReferrer() {
            const _this = this;
            var saveMessage;
            if (_this.assignStaffCode === '') {
                saveMessage = '如果不选，八号地零售系统的会员推荐人将设置为空，心选App中的会员将归还给八号地！！';
            } else {
                saveMessage = '<' + _this.staffName + '>发展的会员将变更给<' + _this.assignStaffName + '>';
            }
            const _p = {
                deptCodes: _this.assignDept.selected,
                delDeptCodes: _this.assignDept.selectedBackup,
                staffCode: _this.assignDept.staff,
                message: saveMessage,
                assignStaffCode: _this.assignStaffCode,
            };
            const _currentDeptCode = _p.deptCodes[0];
            if (_p.staffType) {
                _p.dept = _currentDeptCode;
                _p.deptType = this.assignDept.deptsInGroup.find((r) => r.code == _currentDeptCode).type;
            }
            UrlUtils.PatchRemote(this, this.url.changeReferrer, _p, null, () => {
                _this.$message.success('保存成功');
                this.assignDept.dialogChangeReferrer = false;
            });
        },
        handlePickUpStaffOk(data) {
            const _this = this;
            const _p = {
                code: _this.form.code,
                staffs: data.map((a) => a.code),
                message: '查询结果将添加到本机构组中,是否确认?',
            };
            UrlUtils.PostRemote(_this, _this.url.queryAndAddSave, _p, null, (d) => {
                _this.tableData = _this.duplicate(data.concat(_this.tableData), 'code');
            });
        },
        handleAssignDept(row) {
            this.row = row;
            const _this = this;
            this.assignDept.assignType = row.type;
            this.assignDept.dialogFormVisible = true;
            this.assignDept.staff = row.code;
            Util.queryTable(
                this,
                this.url.allotedDepts,
                {
                    params: {
                        staffCode: row.code,
                        deptGroupCode: this.form.code,
                    },
                },
                (rst) => {
                    _this.assignDept.selected = rst.data.map((r) => r.code);
                    _this.assignDept.selectedBackup = JSON.parse(JSON.stringify(_this.assignDept.selected));
                }
            );
        },
        //商友迁移
        handleAssignDeptOk() {
            const _this = this;
            if (this.row.type == 0 && this.assignDept.selected.length > 0) {
                this.businessFriendMigration(0);
            } else if (this.row.type == 1 && this.assignDept.selected.length > 0) {
                _this
                    .$confirm('员工更换机构后，请选择该员工发展的商友去向', '商友迁移', {
                        distinguishCancelAndClose: true,
                        confirmButtonText: '归还机构',
                        cancelButtonText: '跟随员工',
                        type: 'warning',
                    })
                    .then(() => {
                        this.businessFriendMigration(1);
                    })
                    .catch((action) => {
                        if (action == 'cancel') {
                            this.businessFriendMigration(0);
                        }
                    });
            } else if (this.assignDept.selected.length <= 0) {
                _this.$message({
                    type: 'info',
                    message: '请选择指定员工归属或管理的机构',
                });
            }
        },
        //商友迁移
        businessFriendMigration(cleanFriendsFlag) {
            const _this = this;
            const _p = {
                deptCodes: _this.assignDept.selected,
                delDeptCodes: _this.assignDept.selectedBackup,
                staffCode: _this.assignDept.staff,
                staffType: _this.assignDept.assignType,
                assignStaffCode: _this.assignStaffCode,
                cleanFriendsFlag: cleanFriendsFlag,
            };
            const _currentDeptCode = _p.deptCodes[0];
            if (_p.staffType) {
                _p.dept = _currentDeptCode;
                _p.deptType = this.assignDept.deptsInGroup.find((r) => r.code == _currentDeptCode).type;
            }
            UrlUtils.PatchRemote(this, '/system/staff/allotDept', _p, null, () => {
                this.assignDept.dialogFormVisible = false;
                this.$message.success('操作成功');
                this.handleQuery();
            });
        },
    },
    filters: {
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex === 0) {
                return '男';
            }
            if (sex === 1) {
                return '女';
            }
        },
        roles(roles) {
            return roles ? roles.map((r) => r.name).join(',') : '';
        },
        type(type) {
            if (typeof type == 'undefined') {
                return '';
            }
            if (type === 1) {
                return '业务员工';
            }
            if (type === 0) {
                return '管理员';
            }
        },
    },
};
</script>
<style scoped>
.pClass {
    color: red;
    opacity: 0.7;
    font-size: 120%;
    width: auto;
}

.pClassBlack {
    color: dodgerblue;
    font-size: 120%;
    width: auto;
}

.spanClass {
    font-size: 120%;
}

.qing-icon {
    padding-top: 5px;
    padding-right: 2px;
    font-size: 180%;
}
.option {
    margin-top: 10px;
    margin-bottom: 20px;
}
.option /deep/ .el-radio__label {
    font-size: 21px;
    font-weight: 600;
    line-height: 2;
}
.option .label-detil {
    font-size: 12px;
}
.staffBindDept >>> .el-dialog__body {
    padding-top: 1px;
}
</style>
